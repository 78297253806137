const AppUrls = {
    API_URL: process.env.NEXT_PUBLIC_API_URL,
    WEB_URL: process.env.NEXT_PUBLIC_WEB_URL,
    ADMIN_GATEWAY_URL: process.env.ADMIN_GATEWAY_URL
};

const AppEnv = {
    AUTH_SECRET: process.env.NEXT_PUBLIC_AUTH_SECRET,
    JWT_SECRET: process.env.NEXT_PUBLIC_JWT_SECRET,
    MAINTENANCE_MODE: process.env.NEXT_PUBLIC_MAINTENANCE_MODE
};

export default AppUrls;
