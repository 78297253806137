import { HttpLink }   from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import { setContext } from '@apollo/client/link/context';


// console.log('SERVER_GATEWAY_URL', process.env.ADMIN_GATEWAY_URL);
// console.log('CLIENT_GATEWAY_URL', process.env.NEXT_PUBLIC_ADMIN_GATEWAY_URL);

export const loggerLink = new ApolloLink((operation, forward) => {
    //console.log(`GraphQL Request: ${operation.operationName}`);
    operation.setContext({ start: new Date() });
    return forward(operation).map((response) => {
        // const responseTime = new Date() - operation.getContext().start;
        //console.log(`GraphQL Response took: ${responseTime}`);
        return response;
    });
});

// WITH LOCAL STORAGE - MAY BE IN FUTURE I CAN UTILIZE IT
/*export const authLink = new ApolloLink((operation, forward) => {
	operation.setContext(({headers = {}}) => {
		const token = localStorage.getItem("KEY_ACCESS");
		const authorizationHeader = token ? `Bearer ${token}` : null;
		//console.info("HEADER ", authorizationHeader);

		if (token) {
			headers = {
				...headers,
				authorization: authorizationHeader,
				'client-name': 'DXB Client FrontEnd [web]',
				'client-version': '1.0.0'
			};
		}
		return {headers};
	});

	return forward(operation);
});*/

// TODO IN PROGRESS
// DIRECTLY GETTING JWT FROM AUTH SESSION AND PASSING IT TO GQL CALL HEADERS
export const authLink = setContext(async (_, { headers }) => {
    // const session = await getSession();

    // console.log(JSON.stringify(session, null, 2), 'session');

    const modifiedHeader = {
        headers: {
            ...headers,
            authorization: 'session?.user?.accessToken'
                ? `Bearer ${`session?.user?.accessToken`}`
                : '',
        },
    };

    // console.log('modifiedHeader', JSON.stringify(modifiedHeader, null, 2));

    return modifiedHeader;
});

export const httpLink = new HttpLink({
    // uri: 'http://localhost:7004/graphql',//process.env.ADMIN_GATEWAY_URL,
    uri: process.env.ADMIN_GATEWAY_URL || process.env.NEXT_PUBLIC_ADMIN_GATEWAY_URL,
    credentials: 'include',
});
