import { ReactNode } from 'react';
import { NextPage }  from 'next';
import { AppProps }  from 'next/app';


/*export type ComponentLayout<P = {}> = React.NamedExoticComponent<P> & {
    Layout: React.FC;
};*/

// export type NextComponent<P = {}> = NextComponentType<NextPageContext, unknown, P> & ComponentLayout;

/*export type ReactFCLayout<P = {}> = React.FC<P> & {
    Layout: React.FC;
};*/

// LAYOUT PER PAGE
type GetLayout = (page: ReactNode) => ReactNode;

// eslint-disable-next-line @typescript-eslint/ban-types
type Page<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: GetLayout;
    requireAuth: boolean;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type MyAppProps<P = {}> = AppProps<P> & {
    Component: Page<P>;
};

export const defaultGetLayout: GetLayout = (page: ReactNode): ReactNode => page;


export interface ComponentPropsData<D, E> {
    data: D;
    errors: E;
}
