export const isBrowser = typeof window !== `undefined`;
export const isDev = !(process.env.NODE_ENV === 'production');
export const isProd = process.env.NODE_ENV === 'production';
export const isServer = typeof window === 'undefined';

export const log = (val: any) => {
    if (isDev) console.log(val);
};

export const logN = (title: string, val: any = null) => {
    if (isDev) console.log(title, val);
};

export const logP = (title: string, val: any) => {
    if (isDev) console.log(JSON.stringify(val, null, 2), title);
};

const emailRegix = new RegExp('([!#-\'*+/-9=?A-Z^-~-]+(.[!#-\'*+/-9=?A-Z^-~-]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([!#-\'*+/-9=?A-Z^-~-]+(.[!#-\'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])');

export const validateEmail = (value: string, errorMessage: string) => (emailRegix.test(value) ? null : errorMessage);

export const validateInputLength = (value: string, minLength: number, errorMessage: string) => {

    if (value === null) return;

    return value.length < minLength ? errorMessage : null;
};


/* CITADEL MEMORIES
export const useComponentDidMount = (handler) => useEffect(() => handler(), []);

export const useComponentDidUpdate = (handler, deps) => {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;

            return;
        }

        return handler();
    }, deps);
};

export const useComponentWillUnmount = (handler) =>
    useEffect(() => handler, []);
*/

export function empty(e: any) {
    switch (e) {
        case '':
        case 0:
        case '0':
        case null:
        case false:
        case undefined:
            return true;
        default:
            return false;
    }
}

export const isRequired = (value: string, message: string) => {

    if (empty(value)) {
        return `${message} is required.`;
    }

};

export const isRangeValid = (range) => {
    return range[0] !== null && range[1] !== null;
}
