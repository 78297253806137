import { useMemo }                                           from 'react';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { authLink, httpLink }                                from '@lib/apollo-links';
import { isDev, isServer }                                   from '@core/utils';


let CLIENT;

// console.log('GATEWAY_URL', process.env.ADMIN_GATEWAY_URL);

const links = ApolloLink.from([
    // loggerLink,
    authLink,
    httpLink,
]);

// WITH HOC
export function getApolloClientHOC(initialState, headers, forceNew) {
    if (!CLIENT || forceNew) {
        CLIENT = new ApolloClient({
            ssrMode: isServer,
            cache: new InMemoryCache().restore(initialState || {}),
            connectToDevTools: isDev,
            // link: links,
            link: new HttpLink({
                uri: process.env.ADMIN_GATEWAY_URL,
                // credentials: 'include',
            }),
            /*headers: {
                ...(headers as Record<string, string>)
            }*/
            /*defaultOptions: {
                watchQuery: {
                  ssr: false
                },
                query: {
                  ssr: false
                }
                 // `useQuery(QUERY, { ssr: true });`
            }*/
        });
    }

    return CLIENT;
}

export function getApolloClient() {

    CLIENT = new ApolloClient({
        ssrMode: isServer,
        cache: new InMemoryCache(),
        connectToDevTools: isDev,
        link: links,
    });

    return CLIENT;
}

// THIS IS MORE NATURAL WAY OF DOING IT
//
export function initializeApollo(initialState = null) {
    const _apolloClient = CLIENT ?? getApolloClient();

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();
        _apolloClient.cache.restore({ ...existingCache, ...initialState });
    }

    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient;

    // Create the Apollo Client once in the client
    if (!CLIENT) CLIENT = _apolloClient;
    return _apolloClient;
}

export function useApollo(initialState) {
    const store = useMemo(() => initializeApollo(initialState), [initialState]);
    return store;
}

// WILL GIVE YOU THE LAST CLIENT
export function apolloClient(forceNew = false) {

    if (!CLIENT || forceNew) {
        CLIENT = getApolloClient();
    }

    return CLIENT;
}
