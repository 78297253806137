import React                            from 'react';
import { useRouter }                    from 'next/router';
import { Provider }                     from 'jotai'
import nProgress                        from 'nprogress';
import { SessionProvider }              from 'next-auth/react';
import { ApolloProvider }               from '@apollo/client';
import { useApollo }                    from '@lib/apollo';
import { defaultGetLayout, MyAppProps } from '@core/types/core-types';
import { logP }                         from '@core/utils';
import { AppSeo }                       from "@core/seo/AppSeo";

import '../styles/nprogress.css';


const Application = ({ Component, pageProps }: MyAppProps): JSX.Element => {
    //logN('APP', pageProps);

    const apolloClient = useApollo({});
    const router = useRouter();

    React.useEffect(() => {
        // log('Application Initialized');

        // HIDE LOADING SPINNER
        if (typeof window !== 'undefined') {
            const loader = document.getElementById('globalLoader');
            if (loader) {
                loader.style.display = 'none';
            }
        }

        const handleRouteChange = (url: string, { shallow }) => {
            if (!shallow) {
                nProgress.start();
                logP('START LOADING', url);
            }
        };

        router.events.on('routeChangeStart', nProgress.start);
        router.events.on('routeChangeError', nProgress.done);
        router.events.on('routeChangeComplete', nProgress.done);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
            router.events.off('routeChangeComplete', () => nProgress.done());
            router.events.off('routeChangeError', () => nProgress.done());
        };

    }, []);

    const getLayout = Component.getLayout ?? defaultGetLayout;

    return (
        <SessionProvider
            // Re-fetch session every 5 minutes
            // refetchInterval={5 * 60}
            // Re-fetches session when window is focused
            refetchOnWindowFocus={true}
        >
            <ApolloProvider client={apolloClient}>

                {/*<DefaultSeo {...DEFAULT_SEO_CONFIG} />*/}
                <AppSeo />
                <Provider>
                    {getLayout(<Component {...pageProps} />)}
                </Provider>

            </ApolloProvider>
        </SessionProvider>
    );
};

export default Application;
