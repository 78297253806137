import AppUrls from '@core/constants/app-env';


export const AppMetaData = {
    APP_NAME: 'DXB Stay | Dubai | United Arab Emirates',
    APP_DESCRIPTION: 'DXB Stay (Vacation Rentals Portal) provides a platform through which operators can advertise, market, sell promote and/or offer their products and services for order, purchase, reservation, rent or hire, and through which relevant visitors of the platform can discover, search, compare, and make an order, reservation, purchase, or payment.',
    APP_URL: 'https://www.dxbstay.com/',
    APP_LOCALE: 'en',
    FB_APP_ID: '',
    IMG_SHARE: `${AppUrls.WEB_URL}/icons/512x512.png`,
    KEY_WORDS: '',
    WEB_URL: AppUrls.WEB_URL,
    PRIMARY_COLOR: '#F39C12',
    INSTAGRAM: 'https://www.instagram.com/dxb_stay/'
};

