import { useRouter } from "next/router";
import { NextSeo } from "next-seo";
import urlJoin from "url-join";
import { AppMetaData } from "@core/constants/app-meta-data";
import { OpenGraph } from "next-seo/lib/types";

interface BaseSeoProps {
    title?: string;
    description?: string;
    openGraph?: OpenGraph;
}

export const AppSeo = ({ title, description, openGraph }: BaseSeoProps) => {

    const seoTitle = (title ? `${title} | ` : '');
    const seoDescription = description || "";

    const { asPath } = useRouter();
    const url = urlJoin(AppMetaData.WEB_URL || "", asPath);

    const defaultOpenGraph = {
        title: 'Luxury Vacation Apartment Rentals',
        description:"Discover luxury vacation rental properties that we have to offer in Dubai. All our properties are exclusive and designed to provide you with the best experience Dubai has to offer. We have properties in key locations around Dubai that offer stunning views of breathtaking landmarks.",
        images: [
            {
                url: "https://www.dxbstay.com/_next/static/media/dxbstay-light-logo.fb900a61.svg",
                alt: "dxbstay-logo",
                width: 501.16,
                height: 139.35,
            },
        ],
        siteName: 'dxb-stay',
        url,
        locale: AppMetaData.APP_LOCALE
    }

    const seoOpenGraph = openGraph || defaultOpenGraph;

    return (
        <NextSeo
            title={seoTitle}
            description={seoDescription}
            canonical={url}
            noindex={false}
            openGraph={seoOpenGraph}
        />
    );
}
